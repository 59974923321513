import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanLoad, CanActivate {
    // Define roles and restricted routes
    private restrictedRoutes = {
        'General User': ['users', 'ppm', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'reports', 'inventory', 'calender','hmcassets', 'preventive/template', 'preventive/tpi', '/preventive/checklist', '/preventive/wo'],
        'Supervisor': ['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'reports','hmcassets', 'preventive/template', 'preventive/tpi', '/preventive/checklist'],
        'Operator': ['ppm', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'inventory', 'reports','hmcassets', 'preventive/template', 'preventive/tpi', '/preventive/checklist', '/preventive/wo'],
        'Technician': ['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'inventory', 'reports','hmcassets' , 'preventive/template', 'preventive/tpi', '/preventive/checklist'],
        'Engineer': ['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'preventive/template', 'preventive/tpi', '/preventive/checklist', 'hmcassets'],
        'contrademanager':['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'auditlogs', 'preventive/template', '/preventive/checklist','/preventive/wo', 'hmcassets'],
        'SFE':['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'hmcassets'],
        'AED':['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'hmcassets'],
        'ED':['users', 'dispatch', 'resources', 'store-management', 'masterdata', 'hmcassets']
    };

    constructor(private _router: Router) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this._checkAccess(segments.map(segment => segment.path).join('/'));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this._checkAccess(state.url);
    }

    /**
     * Private method to check access based on user role
     * @param currentPath The current path to check access against
     * @returns Observable<boolean | UrlTree> | boolean | UrlTree
     */
    private _checkAccess(currentPath: string): Observable<boolean | UrlTree> | boolean | UrlTree {
        // Get the user from localStorage
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const userRole = user.roles;

        // If the user role is missing, it means the user is not logged in yet or there is no role information available.
        // In this case, allow the route to load to avoid blocking the initial login process.
        if (!userRole) {
            return true; // Allow the route to load
        }

        // If the user is authenticated and has a role, apply role-based restrictions
        const restrictedPaths = this.restrictedRoutes[userRole] || [];

        // Check if the current route is restricted for the user's role
        if (restrictedPaths.some(path => currentPath.startsWith(path))) {
            // If the route is restricted, return a UrlTree that redirects to "access-denied"
            return this._router.parseUrl('/access-denied');
        }

        // Allow access if the route is not restricted
        return true;
    }
}
