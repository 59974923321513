<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall && navigation">
    <fuse-vertical-navigation
        class="dark bg-primary-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <!-- <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-15"
                    src="favicon-32x32.png"
                    alt="Logo image">
            </div> -->
            <div class="flex items-center justify-center text-primary-400 font-bold tracking-wider mr-2 h-20 mt-3">
                <img
                    class="w-8"
                    src="assets/logohamad.png"
                    alt="Logo image">
                    <!-- BOTNOTCH -->
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bgggg dark:bg-card">

    <!-- Header -->
    <div class="relative flex justify-center w-full overflow-hidden z-49 print:hidden">
        <div class="max-w-360 w-full sm:m-8 sm:mb-0 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card">
            <!-- Top bar -->
            <div class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-1">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall && navigation">
                    <div class="flex items-center mx-2">
                        <div class="flex items-center mx-2">
                            <!-- Light version -->
                            <img
                                class="max-w-[12rem] h-[2.5rem] object-contain dark:hidden"
                                src="assets/logohamad.png" aria-label="light"
                                alt="Logo image">
                            <!-- Dark version -->
                            <img
                                class="hidden dark:flex max-w-[12rem] h-[2.5rem] object-contain"
                                src="assets/logohamad.png" aria-label="dark"
                                alt="Logo image">
                        </div>

                        <ng-container *ngIf="!isScreenSmall">
                            <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
                                <fuse-horizontal-navigation
                                    [name]="'mainNavigation'"
                                    [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                    </button>
                </ng-container>
                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                    <notifications></notifications>
                    <!-- <settings  *ngIf="!isScreenSmall"></settings> w -->
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <user></user>
                </div>
            </div>
            <!-- Bottom bar -->
            <!-- <ng-container *ngIf="!isScreenSmall">
                <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
                    <fuse-horizontal-navigation
                        [name]="'mainNavigation'"
                        [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
                </div>
            </ng-container> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:px-8">
        <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"><div *ngIf="modalService.loading$ | async " class="loader-class custom-class">
        <svg class="animate-spin -ml-1 mb-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
 </svg>
        <p *ngIf="!modalService.contentText || modalService.contentText === ''">Loading... </p>
        <p class="m-0">{{modalService.contentText}}</p>
      </div></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex justify-center w-full print:hidden">
        <div class="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
            <span class="font-medium text-secondary">HAMAD MEDICAL CORPORATION  &copy; {{currentYear}}</span>
        </div>
    </div>

</div>
