import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ModalService } from "../../../../_services/modal.service";
import { BaseRequestService } from 'app/_services/base.service';
import { CommonService } from 'app/_services/common.services';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { cloneDeep } from 'lodash';

@Component({
    selector     : 'thin-layout',
    templateUrl  : './thin.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ThinLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    _overall_navigation: Navigation;
    user : any;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router, public modalService: ModalService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _cs: CommonService,
        private userService : UserService,
        private _changeDetectorRef : ChangeDetectorRef,
        private _baseService : BaseRequestService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user : User) => {
            this.user = user;
            this._changeDetectorRef.detectChanges();
            
        })

        // Subscribe to navigation data
        setTimeout(()=> {
            this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                // this.navigation = navigation;
                if (!this._overall_navigation) {
                    this._overall_navigation = navigation;
                }
                const navi = cloneDeep(this._overall_navigation);
                this.navigation = this._cs.processNavigation(navi);
                this._changeDetectorRef.detectChanges();
            });
        },1000)
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
            this.getAllUsers();
            this.getAllChecklist();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    getAllUsers():void{
        // let params = {query: {bool: {must: [{match: {'species.keyword': 'complianceprofile'}}, {match: {isGlobal: true}}]}}};
        this._baseService
        .doRequest('/api/users','get',null, {skip: 0, limit: 99000, q : {}, orderBy: [{c: {order: 'desc'}}]})
        .subscribe(
            ((res :any)=> {
                if(res && res.data && res.data.length > 0){
                    this._cs.getAllUsers(res.data);
                    this._cs.hmcAllUsers = res.data  
                }
                else {
                    console.log('No Data is coming for users ');
                }
            }),
            ((error :any)=> {
                console.log('error', error);
            })
        )
    }

    getAllChecklist():void{
        this._baseService.doRequest('/api/checklist', 'get', null, {}).subscribe(
            ((res:any)=>{
                if(res && res.total > 0 && res.data.length > 0){
                    this._cs.checkList = res.data;
                }
                else {
                    console.log('No Data is coming in Checklist API');
                }
                
            }),
            ((error:any)=> {
                console.log('Error is coming in Checklist API', error);
            })
        )
    }
}
