import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from 'app/_services/modal.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {

  @ViewChild('detailView', { static: true }) detailView: ElementRef;
  gettingStartedList: any = [];
  showItems : boolean = false;
  loadingreports : boolean = false;
  load : boolean = false;
  urlSafe1 : any ;
  user : any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  releaseNotes : any = 
    {
      "title": "CMMS 1.00.01",
      "date": "1 Oct 2024",
      "type": "HMC",
      "sections": [
        {
          "title": "PPM/CM 1.0.01",
          "date": "13 Oct 2024",
          "type": "p",
          "description": [
            "Corrective Maintenance WO Flow",
            "Planned Preventive Maintenance WO Flow",
            "Remainder Notifications for the PPM and CM",
          ]
        },
        {
          "title": "Assets 1.0.02",
          "date": "7 Oct 2024",
          "type": "p",
          "description": [
            "Assets Mangement ",
            'Assets Capturing',

          ]
        },
        {
          "title": "V2 2.0.01",
          "date": "13 Oct 2024",
          "type": "p",
          "description": [
            'Mobile Application for iOS and Android',
          ]
        },
      ],
  }

  constructor(
    private us : UserService,
    private _changeDetectorRef : ChangeDetectorRef,
    public modalService: ModalService,
    ) {
    this.us.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user : User) => {
      this.user = user;
      this._changeDetectorRef.detectChanges();
  });
  }

  ngOnInit(): void {
  }


  openPanel(data:any):void{
    this.showItems = true;
    this.modalService.open('releaseNotes');
    console.log('data in coming event', data);
  }
}

