<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel($event)" #notificationsOrigin>
    <mat-icon [svgIcon]="'mat_outline:info'"></mat-icon>
</button>

<s-modal id="releaseNotes">
    <div class="mx-auto w-full md:w-[900px] flex flex-col md:flex-row md:max-w-none" >
        <!-- Left Side (info) -->
        <div
          class="hidden md:block md:w-1/3 h-[75vh] max-w-lg bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4"
          fuseScrollbar>
            <p class="mb-4 font-bold text-xl tracking-wider">{{releaseNotes.title}}</p>
            <span
                class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-xs font-bold me-2 px-2.5 py-1 rounded-full">{{releaseNotes.type}}</span>
            <span class="text-sm font-bold tracking-wider uppercase">{{releaseNotes.date}}</span>
            <div class="bg-blue-600/80 rounded-2xl p-3 mt-4">
                <p class="mb-3 text-sm">
                    New Features, Enhancements, and Bug Fixes.
                </p>
            </div>
            <img src="/assets/images/releasenotes.png" alt="Release Notes" class="px-4 mt-10 rounded-xl" />
        </div>

        <!-- Right Side (Notes) -->
        <div class="w-[300px] md:w-2/3 h-[75vh] rounded-lg p-4 overflow-auto relative" fuseScrollbar>
            <button
              class="absolute top-2 right-2 z-10 p-2 rounded-full hover:bg-gray-200"
              mat-icon-button
              aria-label="close modal icon"
              matTooltip="Close"
              (click)="modalService.close('releaseNotes')">
              <mat-icon>close</mat-icon>
            </button>

            <span class="block md:hidden">CMMS's Release Notes</span>
            <div class="col-span-12 space-y-12 relative md:px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700">
                <ng-container *ngFor="let sec of releaseNotes.sections">
                    <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400">
                        <h3 class="text-xl font-semibold tracking-wide">{{sec.title}}</h3>
                        <span class="text-sm font-bold tracking-wider uppercase text-secondary" *ngIf="sec.date">{{sec.date}}</span>
                        <div class="flex flex-row items-start mt-3 gap-2" *ngFor="let des of sec.description">
                            <i class="fas fa-check-circle mt-1 text-green-500"></i>
                            <p class="ml-2"> {{des}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</s-modal>