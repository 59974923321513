import { Component, OnInit } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { BehaviorSubject, Subject } from 'rxjs';
import { OneSignal } from 'onesignal-ngx';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent  implements OnInit
{

    contentText: any;
    showLoader = new BehaviorSubject<boolean>(false);
    loading$ = this.showLoader.asObservable();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    
    /**
     * Constructor
     */
    constructor(private _fuseSplash: FuseSplashScreenService)
    {
    }
    ngOnInit(): void {
      }
}
